import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import * as ga from 'lib/ga';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { isDownloadingOrUploadingSelector } from 'selectors';
import { useAppDispatch } from 'store/store';

import { uploadFileV2Thunk } from '../actions';
import { SUPPORTED_FORMATS } from '../constants';

type UploadButtonProps = {
  className?: string;
};

export const UploadButton: React.FunctionComponent<UploadButtonProps> = (
  props,
) => {
  const { className } = props;
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const { isDownloading, isUploading } = useSelector(
    isDownloadingOrUploadingSelector,
  );

  function handleUploadClick() {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      dispatch(uploadFileV2Thunk(file)).then(() => {
        ga.sendEvent({
          action: 'upload_file',
          label: file.name,
        });
      });

      if (inputRef.current !== null) {
        inputRef.current.value = '';
      }
    }
  }

  return (
    <>
      <input
        type="file"
        id="upload-file-input"
        name="file"
        ref={inputRef}
        className="hidden"
        accept={SUPPORTED_FORMATS.join(',')}
        onChange={handleFileUpload}
        disabled={isDownloading || isUploading}
        tabIndex={-1}
      />
      <button
        className={cn('btn btn-accent link-textcolor', className)}
        onClick={handleUploadClick}
        disabled={isDownloading || isUploading}
      >
        {isUploading
          ? (<span className="loading loading-spinner text-base-content h-6 w-6"/>)
          : (<ArrowUpOnSquareIcon className="h-6 w-6"/>)
        }
        Upload
      </button>
    </>
  );
};
