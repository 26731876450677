import cn from 'classnames';
import { Alerts } from 'components/Alerts';
import { Header } from 'components/Header';
import Head from 'next/head';
import * as React from 'react';

import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '../constants';

type LayoutMainProps = {
  title?: string;
  description?: string;
  isEditor?: boolean;
};

export const LayoutMain: React.FunctionComponent<
  React.PropsWithChildren<LayoutMainProps>
> = (props) => {
  const {
    children,
    description = DEFAULT_DESCRIPTION,
    isEditor = false,
    title = DEFAULT_TITLE,
  } = props;
  return (
    <div className={cn('main-layout-container', {
      'h-screen': isEditor,
    })}>
      <Head>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="initial-scale=1"/>
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <title>{title}</title>
        <meta name="description" content={description}/>
      </Head>

      <header className="main-layout-area-header">
        <Header/>
      </header>

      <main className="main-layout-area-main">
        {children}
      </main>

      <Alerts/>
    </div>
  );
};
