import { createSelector } from '@reduxjs/toolkit';
import { isDownloadingSelector } from 'components/Download/selectors';
import { isUploadingSelector } from 'components/Upload/selectors';

export const isDownloadingOrUploadingSelector = createSelector(
  isDownloadingSelector,
  isUploadingSelector,
  (isDownloading, isUploading) => {
    return {
      isDownloading,
      isUploading,
    };
  },
);
