import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

export const ThemeSwitcher: React.FC = () => {
  const [currentTheme, setCurrentTheme] = React.useState('dark');

  React.useEffect(() => {
    const theme = localStorage.getItem('theme') || 'dark';

    setCurrentTheme(theme);
    document.documentElement.setAttribute('data-theme', localStorage.getItem('theme') || 'dark');
  }, []);

  const toggleTheme = () => {
    const currentTheme = document.documentElement.getAttribute('data-theme');
    const nextTheme = currentTheme === 'dark' ? 'light' : 'dark';

    setCurrentTheme(nextTheme);
    document.documentElement.setAttribute('data-theme', nextTheme);
    localStorage.setItem('theme', nextTheme);
  };

  return (
    <label className="swap swap-rotate px-4">
      <input
        type="checkbox"
        checked={currentTheme === 'light'}
        onChange={toggleTheme}
      />
      <MoonIcon
        className="swap-on h-6 w-6 fill-current"
      />
      <SunIcon
        className="swap-off h-6 w-6 fill-current"
      />
    </label>
  );
};
