import * as ga from 'lib/ga';
import * as React from 'react';
import Dropzone, { DropzoneOptions, FileRejection } from 'react-dropzone';
import { useAppDispatch } from 'store/store';

import { showSystemAlertAction } from '../../Alerts/actions';
import { uploadFileV2Thunk } from '../actions';
import { SUPPORTED_FORMATS } from '../constants';


type UploadDropzoneProps = {
  options?: DropzoneOptions;
};

export const UploadDropzone: React.FunctionComponent<
  React.PropsWithChildren<UploadDropzoneProps>
> = ({ options = {}, children }) => {
  const dispatch = useAppDispatch();

  function handleFileUpload(files: File[]) {
    if (files && files.length > 0) {
      dispatch(uploadFileV2Thunk(files[0])).then(() => {
        ga.sendEvent({
          action: 'upload_file',
          label: 'dropzone',
        });
      });
    }
  }

  function showRejectedAlert(fileRejections: FileRejection[]) {
    dispatch(
      showSystemAlertAction({
        severity: 'error',
        text: `Sorry, the format of the file ${fileRejections[0].file.name} is not supported.`,
      }),
    );
  }

  return (
    <Dropzone
      accept={SUPPORTED_FORMATS}
      multiple={false}
      onDropAccepted={handleFileUpload}
      onDropRejected={showRejectedAlert}
      {...options}
    >
      {({ getRootProps, getInputProps }) => (
        <div className="h-full w-full overflow-hidden" {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
};
