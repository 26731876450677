import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {ErrorAlertIcon} from "../Icons/ErrorAlertIcon";
import {InfoAlertIcon} from "../Icons/InfoAlertIcon";
import {SuccessAlertIcon} from "../Icons/SuccessAlertIcon";
import {WarningAlertIcon} from "../Icons/WarningAlertIcon";
import { hideSystemAlertAction } from './actions';
import { systemAlertSelector } from './selectors';

const DEFAULT_DURATION = 6000;

const mapIcons = {
  'error': ErrorAlertIcon,
  'info': InfoAlertIcon,
  'success': SuccessAlertIcon,
  'warning': WarningAlertIcon,
}

export const Alerts: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const alert = useSelector(systemAlertSelector);

  const handleClose = React.useCallback(() => {
    clearTimeout(timeoutRef.current);
    dispatch(hideSystemAlertAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (alert.id) {
      timeoutRef.current = setTimeout(() => {
        handleClose();
      }, alert.duration || DEFAULT_DURATION);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [alert.duration, alert.id, handleClose]);

  const SvgComponent = mapIcons[alert.severity || 'warning'];

  if (!alert.id) {
    return null;
  }

  return (
    <div className="toast toast-center toast-top">
      <div role="alert" className={cn('alert', {
        'alert-error': alert.severity === 'error',
        'alert-info': alert.severity === 'info',
        'alert-success': alert.severity === 'success',
        'alert-warning': alert.severity === 'warning',
      })}>
        <SvgComponent />
        <span>{alert.text}</span>
        <button className="btn btn-ghost" onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
  );
};
