export const SUPPORTED_FORMATS = [
  '.save',
  '.rpgsave',
  '.rmmzsave',
  '.rvdata',
  '.rvdata2',
  '.rxdata',
  '.nson',
  '.json',
  '.dat',
  '.data',
  '.sav',
];
