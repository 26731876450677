import cn from 'classnames';
import { savefileNameSelector } from 'components/Editor/selectors';
import { isUploadingSelector } from 'components/Upload/selectors';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ThemeSwitcher } from './ThemeSwitcher';

export const Header: React.FunctionComponent = () => {
  const router = useRouter();
  const isUploading = useSelector(isUploadingSelector);
  const savefileName = useSelector(savefileNameSelector);
  const isEditorPage = router.pathname === '/editor';
  const isDemoPage = router.pathname === '/demo';
  const isEditorOrDemoPage = isEditorPage || isDemoPage;
  const [isMenuActive, setIsMenuActive] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuRef]);

  const handleToggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  return (
    <div className="flex h-full w-full px-5">
      <div className="flex w-1/3 items-center sm:w-3/12 lg:w-1/3">
        <Link href="/" passHref>
          <a className="btn btn-ghost">
            Save Editor
          </a>
        </Link>
      </div>

      <div className="flex w-1/3 items-center justify-center sm:w-3/12 lg:w-1/3">
        {!isUploading && isEditorOrDemoPage && savefileName && (
          <h2>
            {savefileName}
          </h2>
        )}
        {isUploading && isEditorOrDemoPage && <span className="loading loading-dots loading-md"></span>}
      </div>

      <nav className="flex w-1/3 items-center justify-end sm:w-1/2 lg:w-1/3" ref={menuRef}>
        <ThemeSwitcher/>
        <label className="btn btn-circle swap swap-rotate sm:hidden">
          <input type="checkbox" onChange={handleToggleMenu} checked={isMenuActive}/>
          <svg className="swap-off fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
               viewBox="0 0 512 512">
            <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"/>
          </svg>
          <svg className="swap-on fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
               viewBox="0 0 512 512">
            <polygon
              points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"/>
          </svg>
        </label>
        <ul className={cn({
          'hidden sm:flex sm:items-center sm:justify-end sm:gap-2': !isMenuActive,
          'menu border border-gray-400 rounded-box absolute right-5 top-16 z-50 w-40 bg-base-200': isMenuActive,
        })}>
          <li>
            <Link href="/renpy-repack" passHref>
              <a className="btn btn-ghost content-center whitespace-nowrap font-normal normal-case" target="_blank">
                {`Ren'Py Repack`}
              </a>
            </Link>
          </li>
          {!isEditorOrDemoPage && (
            <>
              <li>
                <Link href="/#faq" passHref>
                  <a className="btn btn-ghost content-center font-normal">
                    FAQ
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/demo" passHref>
                  <a className="btn btn-ghost content-center font-normal normal-case" target="_blank">
                    Demo
                  </a>
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};
